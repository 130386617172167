<template>
    <Header />
    <PageHeader title="Centre des Assurances Santé"  :fontSize="'1.50rem'" :goBack="customGoBack" />

    <div class="dashboard-healthy-page">

        <div class="btn-large-container">
            <button class="btn-add-assurance" @click="createAssurance">
                Ajouter une assurance <i class="fas fa-plus-circle"></i>
            </button>
            <button class="btn-notification" @click="showModalNotifications = true">
                <i class="fas fa-bell"></i>
            </button>
        </div>   
        
    </div>

    <Footer :key="footerKey" />
    
</template>


<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';

import { checkAuth  } from '@/utils/validationUtils';

export default {
  name: 'DashboardHealthy',

  components: {
      Header,
      PageHeader,
      Footer,
  },

  created() {
    checkAuth();
  },

  data() {
    return {
        

    };
  },

  methods: {
    createAssurance() {
        this.$router.push('/subscribe-healthy');
    },
  },


};

</script>


<style scoped>

.dashboard-healthy-page {
max-width: 400px;
margin: auto;
padding: 0px;
text-align: center;
color: #ffffff;
padding-left: 10px;
padding-right: 10px;
margin-bottom: 5.5%;
font-size: 0.8em;
}

/************ HEADER SEARCH **************/
.btn-large-container {
    display: flex;
    gap: 10px; /* Espace entre les boutons */
    align-items: center;
    margin-top: 20px;
}

.btn-large-container .btn-add-assurance {
    flex: 9; /* 90% de la largeur */
    background-color: #016A40; /* Couleur de fond pour le bouton d'épargne */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-large-container .btn-add-assurance:hover {
    background-color: #005934;
}

.btn-large-container .btn-add-assurance:active {
    background-color: #003822;
}

.btn-large-container .btn-notification {
    flex: 1; /* 10% de la largeur */
    background-color: #f0f0f0; /* Couleur de fond pour le bouton de notification */
    color: #000;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.btn-large-container .btn-notification:hover {
    /*background-color: #e0e0e0; Couleur de fond au survol*/
    color: #333; /* Couleur du texte au survol */
}

.btn-large-container .btn-notification:active {
    background-color: #d0d0d0; /* Couleur de fond lorsque le bouton est enfoncé */
    /*color: #000; /* Couleur du texte lorsque le bouton est enfoncé */
}



</style>