<template>
  <Header />
  <PageHeader title="Inscription Assurances Santé" :fontSize="'1.50rem'" :goBack="customGoBack" />

  <div class="subscribe-page">

    <div class="plan-selection" v-if="planIndex !== 5">
      <button
        v-for="(plan, index) in plans"
        :key="index"
        :class="['plan-button', { active: selectedPlan === plan.value }]"
        @click="onSelectedPlanChange(plan.value)"
      >
        <i :class="plan.icon"></i>
        {{ plan.label }}
      </button>
    </div>

    <div v-if="unavailable" class="plan-unavailable">
      Nous travaillons activement pour rendre ce plan d’assurance disponible. Merci de votre patience. En attendant, vous pouvez explorer d'autres options adaptées à vos besoins.
    </div>
    <div v-else-if="planIndex === 1">

      <div class="plan-type-selection" id="plan-type-selection">
        <div class="title"><i class="fas fa-hospital-user"></i> Choix du type de PLAN</div>
        <span v-if="typeError" class="error-message">{{ typeError }}</span>

        <div 
          v-for="(type, index) in planTypes" 
          :key="index" 
          class="plan-option"
          :class="{ selected: selectedType === type.name }"
          @click="selectPlanType(type)"
        >
          <!-- ✅ Bouton radio -->
          <div class="block-option">
            <input 
              type="radio" 
              :id="type.name" 
              :value="type.name" 
              v-model="selectedType"
            />
            
            <!-- 📄 Texte -->
            <label :for="type.name" class="plan-text">
              <strong>{{ type.name }} :</strong> {{ type.description }}
            </label>

            <!-- 🖼 Image -->
            <img :src="getImage(type.image_url)" :alt="type.name" class="plan-image" />
          </div>
          
          <div class="type-price"> {{ type.monthly_price }} HTG/Mois</div>
        </div>
      </div>

      <div class="validation-form" id="personal-info-box">
        <div class="title"><i class="fas fa-user-tie"></i> Informations personnelles</div>

        <div class="form-group">
          <label for="fullName">
            <i class="fas fa-user"></i> Nom complet:
          </label>
          <p>{{ personalInfo.fullName }}</p>
        </div>

        <div class="form-group">
          <label for="dob">
            <i class="fas fa-calendar-alt"></i> Date de naissance:
          </label>
          <p>{{ formatShortDateText(personalInfo.birthdate, false) }} ({{ personalInfo.age }} ans)</p>
        </div>

        <div class="form-group">
          <label for="gender">
            <i class="fas fa-venus-mars"></i> Genre:
          </label>
          <p>{{ personalInfo.gender }}</p>
        </div>

        <div class="form-group">
          <label for="email">
            <i class="fas fa-envelope"></i> Adresse e-mail:
          </label>
          <p>{{ personalInfo.email }}</p>
        </div>

        <div class="form-group">
          <label for="phone">
            <i class="fas fa-phone-alt"></i> Numéro de téléphone:
          </label>
          <p>{{ personalInfo.phone }}</p>
        </div>

        <div class="form-group">
          <label for="address">
            <i class="fas fa-map-marker-alt"></i> Adresse physique:
          </label>
          <p>{{ personalInfo.address }}</p>
        </div>

        <div class="form-actions">
          <button 
            class="btn btn-confirm" 
            :class="{ 'confirmed': personalInfoConfirm }"
            @click="confirmDetails"
          >
            <span v-if="personalInfoConfirm">
              <i class="fas fa-check-circle"></i> Confirmé
            </span>
            <span v-else>
              <i class="fas fa-circle"></i> Confirmer
            </span>
          </button>
        </div>

        <span v-if="personalError" class="error-message-blank">{{ personalError }}</span>


      </div>

      <div class="medical-form" id="medical-form">
        <div class="title"><i class="fas fa-heart-pulse"></i> Conditions de santé</div>
        <!-- Allergies -->
        <div class="question" id="allergiesInput">
          <label><i class="fas fa-exclamation-triangle icon-bg"></i> Avez-vous des allergies connues ?</label>
          <select v-model="medical.allergies" @change="resetAllergiesDetails">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </select>
          <div v-if="medical.allergies === 'oui'">
            <textarea 
              ref="allergiesInput"
              v-model="medical.allergiesDetails" 
              @input="handleAllergiesInput"
              placeholder="Précisez vos allergies"
            >
            </textarea>
          </div>
          <span v-if="allergiesError" class="error-message">{{ allergiesError }}</span>
        </div>

        <!-- Maladies Chroniques -->
        <div class="question" id="maladiesInput">
          <label><i class="fas fa-stethoscope icon-bg"></i> Avez-vous des maladies chroniques ?</label>
          <select v-model="medical.maladiesChroniques" @change="resetMaladiesDetails">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </select>
          <div v-if="medical.maladiesChroniques === 'oui'">
            <textarea
              ref="maladiesInput"
              v-model="medical.maladiesDetails" 
              @input="handleMaladiesInput"
              placeholder="Précisez les maladies chroniques"
            >
            </textarea>
          </div>
          <span v-if="maladiesError" class="error-message">{{ maladiesError }}</span>
        </div>

        <!-- Interventions Chirurgicales -->
        <div class="question" id="chirurgieInput">
          <label><i class="fas fa-hospital icon-bg"></i> Avez-vous subi des interventions chirurgicales récentes ?</label>
          <select v-model="medical.chirurgie" @change="resetChirurgieDetails">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </select>
          <div v-if="medical.chirurgie === 'oui'">
            <textarea
              ref="chirurgieInput"
              v-model="medical.chirurgieDetails" 
              @input="handleChirurgieInput"
              placeholder="Précisez les interventions récentes"
            >
          </textarea>
          </div>
          <span v-if="chirurgieError" class="error-message">{{ chirurgieError }}</span>
        </div>

        <!-- Médicaments -->
        <div class="question" id="medicamentsInput">
          <label><i class="fas fa-capsules icon-bg"></i> Prenez-vous des médicaments régulièrement ?</label>
          <select v-model="medical.medicaments" @change="resetMedicamentsDetails">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </select>
          <div v-if="medical.medicaments === 'oui'">
            <textarea 
              ref="medicamentsInput"
              v-model="medical.medicamentsDetails" 
              @input="handleMedicamentsInput"
              placeholder="Précisez les médicaments que vous prenez"
            >
            </textarea>
          </div>
          <span v-if="medicamentsError" class="error-message">{{ medicamentsError }}</span>
        </div>

        <!-- Groupe sanguin -->
        <div class="question">
          <label><i class="fas fa-droplet icon-bg"></i> Groupe sanguin :</label>
          <select v-model="medical.groupeSanguin">
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="inconnu">Je ne sais pas</option>
          </select>
        </div>

        <div class="question" id="agreedMedicalFormInput">
          <label class="checkbox-label">
            <input 
              type="checkbox" 
              v-model="medical.agreedMedicalForm"
              @change="handleCheckboxMedicalForm" 
            />
            <p>
              Je comprends que ces informations devront être validées lors de ma visite dans l'une de vos cliniques ou hôpitaux parteniares. 
              Un rappel pour planifier cette visite sera envoyer
            </p>
          </label>          
          <span v-if="agreedMedicalFormError" class="error-message">{{ agreedMedicalFormError }}</span>
        </div>

      </div>

      <div class="form-contact">
        <h3><i class="fas fa-user-shield"></i> Personne à contacter en cas d'urgence</h3>

        <!-- Nom complet -->
        <div class="input-group">
          <label for="nom"><i class="fas fa-user"></i> Nom complet :</label>
          <input type="text" id="contact-nom" v-model="contact.nom" @input="handleContactNameInput" placeholder="Entrez le nom complet" />
          <span v-if="contactNameError" class="error-message-blank-left">{{ contactNameError }}</span>
        </div>

        <!-- Relation -->
        <div class="input-group">
          <label for="contact-relation"><i class="fas fa-users"></i> Relation :</label>
          <select id="contact-relation" v-model="contact.relation" @change="onChangeContactRelation">
            <option value="">Sélectionnez</option>
            <option value="Conjoint">Conjoint</option>
            <option value="Parent">Parent</option>
            <option value="Enfant">Enfant</option>
            <option value="Ami">Ami</option>
            <option value="Autre">Autre</option>
          </select>
          <span v-if="contactRelationError" class="error-message-blank-left">{{ contactRelationError }}</span>
        </div>

        <!-- Numéro de téléphone -->
        <div class="input-group">
          <label for="telephone"><i class="fas fa-phone"></i> Numéro de téléphone :</label>
          <input type="tel" id="contact-telephone" v-model="contact.telephone" @input="handleContactPhoneInput" placeholder="Entrez le numéro" />
          <span v-if="contactPhoneError" class="error-message-blank-left">{{ contactPhoneError }}</span>
        </div>
      </div>

      <div class="subscription-container">
        <h3>Choisissez votre mode de paiement</h3>

        <div class="payment-options">
          <button
            v-for="(option, index) in paymentOptions"
            :key="index"
            :class="['payment-button', { active: selectedPayment === option.value }]"
            @click="onSelectedPaymentChange(option.value)"
          >
            <div class="title">
              <i :class="option.icon"></i>
              {{ option.label }}
            </div>
            <div class="price"> {{ option.price }} HTG / {{  option.tag }}</div>
          </button>
        </div>

        <!-- Checkbox pour prélèvement automatique -->
        <div class="auto-debit">
          <input type="checkbox" id="autoDebit" v-model="autoDebit" />
          <label for="autoDebit">
            <i class="fas fa-sync-alt"></i> Activer le prélèvement automatique
          </label>
        </div>
      </div>

      <div class="box-terms">
        <label class="checkbox-label">
          <input 
            id="termsCondtionsInput"  
            type="checkbox" 
            v-model="termsCondition"
            @change="handleTermsCondtions" 
          />
          <p>
            J'accepte qu'en soumettant ce formulaire, j'autorise Kòb Dirèk à utiliser mes informations médicales 
            pour m'offrir les meilleurs services d'assurance. Ces informations seront partagées avec les partenaires 
            médicaux uniquement pour ma prise en charge.
          </p>
        </label>          
        <span v-if="termsConditionError" class="error-message">{{ termsConditionError }}</span>
      </div>

      <button type="submit" class="btn-submit" :disabled="loading" id="submit-subscribe">
        <span v-if="loading">
          Traitement en cours <i class="fas fa-spinner fa-spin"></i>
        </span>
        <span v-else @click="submitSubscription">
          Souscrire <i class="fas fa-shield-heart"></i>
        </span>
      </button>
      
      <br>
      <span v-if="apiError" class="error-message">{{ apiError }}</span>

    </div>

    <div class="block-a2f" v-if="planIndex === 5">
        <!-- Import dynamique de l'image -->
        <img :src="require('@/assets/images/ewallet_18.png')" 
            alt="Confirmation d'enregistrement" 
            class="mockup-image" />

        <p><b>Félicitations !</b> Votre souscription à l'assurance santé a été effectuée avec succès.<br/><br/></p> 

        <p>📍 
          <b>Prochaine étape :</b> Rendez-vous dans l'une de nos cliniques ou hôpitaux partenaires pour activer votre plan.<br/>
          <a href="/healthy-partner">🏥 Voir la liste des établissements partenaires 🔗</a><br/><br/>
        </p>  

        <p>Vous pouvez également consulter les détails de votre souscription dans votre tableau de bord.</p>  

        <!-- Bouton avec aria-label pour accessibilité -->
        <button @click="goToDashBoard" class="btn-submit" aria-label="Accéder au tableau de bord">
            <i class="fas fa-tachometer-alt"></i> Tableau de bord
        </button>
    </div>


  </div>

  <Footer />
</template>

<script>
import Header from '../shared/HeaderPage.vue';
import Footer from '../shared/FooterPage.vue';
import PageHeader from '../shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../../config';
import { checkAuth, formatShortDateText, validateFullName, validatePhone } from '@/utils/validationUtils';

export default {
  name: 'SubscribeHealthy',

  components: {
    Header,
    PageHeader,
    Footer
  },

  created() {
    checkAuth();
  },

  mounted() {     
    // this.initiateFilters();
    this.fetchPlansType(); // Appel à l'API pour récupérer les économies une fois le composant monté
  },

  data() {
    return {
      loadingFetch: true,
      loading: false,
      unavailable: false,
      planIndex: 1,
      selectedPlan: 'personal', // Sélection par défaut
      plans: [
        { label: 'Personnels', value: 'personal', icon: 'fas fa-user' },
        { label: 'Familiaux', value: 'family', icon: 'fas fa-users' },
        { label: 'Entreprises', value: 'business', icon: 'fas fa-building' }
      ],

      selectedType: '', // Sélection par défaut
      planTypes: [],
      typeError: '',
      
      personalInfoConfirm: false,
      personalError: '',
      personalInfo: [],

      selectedPayment: 'monthly', // Option sélectionnée par défaut
      autoDebit: false, // Prélèvement automatique désactivé par défaut
      paymentOptions: [
        { label: 'Mensuel', value: 'monthly', icon: 'fas fa-calendar-alt', price: 0, tag: 'Mo' },
        { label: 'Trimestriel', value: 'quarterly', icon: 'fas fa-calendar' , price: 0, tag: '3 Mo' },
        { label: 'Annuel', value: 'yearly', icon: 'fas fa-calendar-check', price: 0, tag: 'An' }
      ],

      medical: {
        allergies: '',
        allergiesDetails: '',
        maladiesChroniques: '',
        maladiesDetails: '',
        chirurgie: '',
        chirurgieDetails: '',
        medicaments: '',
        medicamentsDetails: '',
        groupeSanguin: 'inconnu',
        agreedMedicalForm: false
      },

      allergiesError: '',
      maladiesError: '',
      chirurgieError: '',
      medicamentsError: '',
      agreedMedicalFormError: '',

      contact: {
        nom: '',
        relation: '',
        telephone: ''
      },
      contactNameError: '',
      contactRelationError: '',
      contactPhoneError: '',

      termsCondition: false,
      termsConditionError: '',
      apiError: '',
    };
  },

  methods: {

      formatShortDateText(date, showTime = false) {
        return formatShortDateText(date, showTime)
      },

      goToDashBoard() {
          this.$router.push('/dashboard-healthy');
      },


      async fetchPlansType() {
          this.loadingFetch = true;
          const url = `${SERVER_IP}/health-insurance/getPlans`;
          this.planTypes = [];

          try {
              
              const response = await axios.post(url, {});

              if (response.status === 200) {
                  // On met à jour les données locales après réception de l'API
                  this.planTypes = response.data.plans; // Tableau des économies
                  this.fetchPersonalInfo();
              }
              
          } catch (error) {
              console.log(error.response.data.message);
          } finally {
              this.loadingFetch = false;
          }
      },

      async fetchPersonalInfo() {
          this.loadingFetch = true;
          const url = `${SERVER_IP}/health-insurance/getPersonalInfoUser`;
          this.personalInfo = [];

          try {
              
              const response = await axios.post(url, {userId: checkAuth()});

              if (response.status === 200) {
                  // On met à jour les données locales après réception de l'API
                  this.personalInfo = response.data.userInfo; // Tableau des économies
                  console.log(this.personalInfo);
              }

              
          } catch (error) {
              console.log(error.response.data.message);
          } finally {
              this.loadingFetch = false;
          }
      },

      onSelectedPlanChange(plan) {
        this.selectedPlan = plan;
        if(plan !== 'personal') {
          this.unavailable = true;
        }
        else if (plan === 'personal') {
          this.planIndex = 1;
          this.unavailable = false;
        }
      },

      selectPlanType(type) {
        this.selectedType = type;
        this.typeError = "";
        this.scrollToError("personal-info-box");

        // Mise à jour des prix pour chaque option de paiement
        this.paymentOptions = this.paymentOptions.map(option => {
            let updatedPrice;

            if (option.value === 'monthly') {
                updatedPrice = type.monthly_price;
            } else if (option.value === 'quarterly') {
                updatedPrice = type.quarterly_price;
            } else if (option.value === 'yearly') {
                updatedPrice = type.annual_price;
            }

            return { ...option, price: updatedPrice };
        });
      },

      onSelectedPaymentChange(value) {
        this.selectedPayment = value;
      },

      scrollToError(field) {
          const element = document.getElementById(field);
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
          this.loading = false;
      },

      getImage(imagePath) {
        // Si imagePath est une URL complète, la retourner directement
        if (imagePath.startsWith('http')) {
          return imagePath;
        }
        // Si imagePath est un chemin relatif, le compléter pour retourner l'URL complète
        return require(`@/${imagePath}`);
      },

      confirmDetails() {
        this.personalInfoConfirm = !this.personalInfoConfirm;
        this.personalError = "";
        this.scrollToError("medical-form");
      },

      resetAllergiesDetails() {
        if (this.medical.allergies !== 'oui') {
          this.medical.allergiesDetails = '';
          this.allergiesError = '';
        }
        else {
          this.$nextTick(() => {
            this.$refs.allergiesInput.focus();
          });
        }
      },
      handleAllergiesInput() {
        this.allergiesError = '';
        this.apiError = '';
      },

      resetMaladiesDetails() {
        if (this.medical.maladiesChroniques !== 'oui') {
          this.medical.maladiesDetails = '';
          this.maladiesError = '';
        }
        else {
          this.$nextTick(() => {
            this.$refs.maladiesInput.focus();
          });
        }
      },
      handleMaladiesInput() {
        this.maladiesError = '';
        this.apiError = '';
      },

      resetChirurgieDetails() {
        if (this.medical.chirurgie !== 'oui') {
          this.medical.chirurgieDetails = '';
          this.chirurgieError = '';
        }
        else {
          this.$nextTick(() => {
            this.$refs.chirurgieInput.focus();
          });
        }
      },
      handleChirurgieInput() {
        this.chirurgieError = '';
        this.apiError = '';
      },

      handleCheckboxMedicalForm() {
        this.agreedMedicalFormError = '';
        this.apiError = '';
        if(this.medical.agreedMedicalForm) {
          this.scrollToError("autoDebit");
        }
      },

      resetMedicamentsDetails() {
        if (this.medical.medicaments !== 'oui') {
          this.medical.medicamentsDetails = '';
          this.medicamentsError = '';
        }
        else {
          this.$nextTick(() => {
            this.$refs.medicamentsInput.focus();
          });
        }
      },
      handleMedicamentsInput() {
        this.medicamentsError = '';
        this.apiError = '';
      },

      handleContactNameInput() {
        this.contactNameError = '';
        this.apiError = '';
      },
      handleContactPhoneInput() {
        this.contactPhoneError = '';
        this.apiError = '';
      },
      onChangeContactRelation() {
        this.contactRelationError = '';
        this.apiError = '';
      },

      handleTermsCondtions() {
        this.termsConditionError = '';
        this.apiError = '';
      },

      checkErrorInMedicalForm() {
        
        if ((this.medical.allergies === 'oui'  && !this.containsWord(this.medical.allergiesDetails)) || (this.medical.allergies === ''))  {
          this.allergiesError = 'Il faut preciser vos allergies!';
          this.scrollToError('allergiesInput');
          return false;
        }

        if ((this.medical.maladiesChroniques === 'oui' && !this.containsWord(this.medical.maladiesDetails)) || (this.medical.maladiesChroniques === '') )  {
          this.maladiesError = 'Il faut preciser vos maladies!';
          this.scrollToError('maladiesInput');
          return false;
        }

        if ((this.medical.chirurgie === 'oui' && !this.containsWord(this.medical.chirurgieDetails)) || (this.medical.chirurgie === '') )  {
          this.chirurgieError = 'Il faut preciser les opérations que vous avez subi!';
          this.scrollToError('chirurgieInput');
          return false;
        }

        if ((this.medical.medicaments === 'oui' && !this.containsWord(this.medical.medicamentsDetails)) || (this.medical.medicaments === '') )  {
          this.medicamentsError = 'Il faut preciser les médicaments que vous prenez actuellement!';
          this.scrollToError('medicamentsInput');
          return false;
        }

        if(!this.medical.agreedMedicalForm) {
          this.agreedMedicalFormError = `Soyez sur que vous comprenez l'obligation de passer dans une des instances concernées pour valider votre assurance`;
          this.scrollToError('agreedMedicalFormInput');
          return false;
        }

        return true;
      },

      checkContactError() {
        let validationResult = validateFullName(this.contact.nom);
        if(!validationResult.isValid){
            this.contactNameError = validationResult.errorMessage;
            this.scrollToError('contact-nom');
            return;
        }

        validationResult = validatePhone(this.contact.telephone);
        if(!validationResult.isValid){
            this.contactPhoneError = validationResult.errorMessage;
            this.scrollToError('contact-telephone');
            return;
        }

        if(this.contact.relation === '') {
            this.contactRelationError = 'Veuillez préciser votre relation avec ce contact';
            this.scrollToError('contact-relation');
            return;
        }

        return true;
      },

      async submitSubscription() {

        this.loading = true;

        if(this.selectedType === '') {
            this.typeError = `Il faut choisir un type de plan d'assurance !`;
            this.scrollToError('plan-type-selection');
            this.loading = false;
            return;
        }

        if(!this.personalInfoConfirm) {
            this.personalError = `Il faut confirmer les informations personnelles`;
            this.scrollToError('personal-info-box');
            this.loading = false;
            return;
        }

        if(!this.checkErrorInMedicalForm() ) {
            this.loading = false;
            return;
        }

        if(!this.checkContactError() ) {
            this.loading = false;
            return;
        }

        if(!this.termsCondition) {
            this.termsConditionError = `Veuillez accepter les termes et conditions!`;
            this.scrollToError('termsCondtionsInput');
            this.loading = false;
            return;
        }

        const url = `${SERVER_IP}/health-insurance/subscribe`;
        const userId = checkAuth();
        try {
            const response = await axios.post(url, {
              userId: userId,
              plan: this.selectedPlan,
              type: this.selectedType,
              medical: this.medical,
              contact: this.contact,
              typePayement: this.selectedPayment,
              autoPayment: this.autoDebit
            });

            if (response.status === 200) {
                this.planIndex = 5;
            }
            
        } catch (error) {
          if (error.response && error.response.data.error === 'subscriber_existed'){
              this.apiError = `Vous possédez déjà un plan personnel. 
                Vous ne pouvez pas en créer un autre. Consultez votre tableau de bord pour le mettre à niveau, 
                le rétrograder ou l’annuler
                `;
              this.scrollToError('submit-subscribe');
          }
          else {
            this.apiError = 'Une erreur systeme est survenue. Veuillez réesayer à nouveau !';
            this.scrollToError('submit-subscribe');
          }
        } finally {
            this.loading = false;
        }


      },

      containsWord(input) {
        return /\w+/.test(input);
      },

  },

};
</script>


<style scoped>

    .subscribe-page {
      max-width: 400px;
      margin: auto;
      padding: 0px;
      text-align: center;
      color: #ffffff;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5.5%;
      font-size: 0.8rem;
      margin-bottom: 20%;
    }

    .plan-selection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 10px;
      margin-top: 10px;
    }

    .plan-button {
      flex: 1;
      padding: 15px;
      text-align: center;
      border: none;
      border-radius: 8px;
      background-color: #e0e0e0;
      color: #333;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
    }

    .plan-button i {
      margin-right: 2px;
    }

    .plan-button.active {
      background-color: #016a40;;
      color: white;
    }

    .plan-unavailable {
      text-align: justify;
      text-align: center;
      margin-top: 35%;
      font-size: 1rem;
    }

    .plan-type-selection {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .plan-type-selection .title {
      font-size: 1.1rem;
      text-align: left;
      margin-top: 5%;
      margin-bottom: 2%;
    }

    .plan-option {
      align-items: center;
      padding: 12px;
      border: 2px solid #ccc;
      border-radius: 8px;
      cursor: pointer;
      transition: background 0.3s, border-color 0.3s;
    }

    .block-option {
      display: flex;
    }

    .plan-option.selected {
      border-color: #28a745;
      background: rgba(40, 167, 69, 0.1);
    }

    input[type="radio"] {
      margin-right: 10px;
    }

    .plan-text {
      flex: 1;
      text-align: left;
      /* text-align: justify; */
    }
    .type-price{
      width: 100%;
      margin: 2% 0%;
    }

    .plan-image {
      width: 80px;
      height: 80px;
      border-radius: 10px;
      object-fit: cover;
    }

    .error-message {
      font-size: 0.8em; /* Ajustez la taille de la police selon vos besoins */
      font-weight: normal; /* Ajustez le poids de la police si nécessaire */
      transition: font-size 0.3s; /* Transition pour une animation douce */
      color: #FFFC00; /* Couleur du texte rouge */
      font-style: italic; /* Texte en italique */
      text-align: left;
    }


    /* //INFO PERSONNEL */

    .validation-form {
      display: flex;
      flex-direction: column;
      margin-top: 5%;
      background-color: #ffffff;
      padding: 3% 2%;
      /* padding-bottom: 2%; */
      border-radius: 15px;
    }

    .validation-form .title {
      font-size: 1.1rem;
      text-align: left;
      margin-bottom: 5%;
      color: #000;
    }

    .form-group {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      /* font-size: 1rem; */
    }

    .form-group label {
      font-weight: bold;
      margin-right: 10px;
      color: #000;
    }

    .form-group i {
      margin-right: 10px;
      color: #FFF;
      background-color: #000;
      border-radius: 50%;
      padding: 5px;
    }

    .form-group p {
      margin: 0;
      color: #555;
      text-align: right;
      display: inline-block;
    }

    .form-actions {
      text-align: center;
      margin-top: 20px;
    }

    .btn-confirm {
      color: white;
      padding: 10px 20px;
      border: none;
      font-size: 1rem;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease;
    }

    .btn-confirm {
      background-color: #28a745; /* Par défaut */
    }

    .btn-confirm.confirmed {
      background-color: #016a40; /* Quand confirmé */
    }

    .btn-confirm i {
      margin-right: 8px;
    }

    .error-message-blank {
      font-size: 0.8rem; /* Ajustez la taille de la police selon vos besoins */
      font-weight: normal; /* Ajustez le poids de la police si nécessaire */
      transition: font-size 0.3s; /* Transition pour une animation douce */
      color: #6e1d08; /* Couleur du texte rouge */
      font-style: italic; /* Texte en italique */
      text-align: center;
      margin-top: 2%;
    }

    .error-message-blank-left {
      /* font-size: 0.8rem; Ajustez la taille de la police selon vos besoins */
      font-weight: normal; /* Ajustez le poids de la police si nécessaire */
      transition: font-size 0.3s; /* Transition pour une animation douce */
      color: #6e1d08; /* Couleur du texte rouge */
      font-style: italic; /* Texte en italique */
      text-align: left;
      margin-top: 2%;
    }

    .subscription-container {
      text-align: center;
      padding: 10px 0px;
    }

    .payment-options {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }

    .payment-button {
      flex: 1;
      padding: 12px 0px;
      margin: 0 4px;
      border: 2px solid #ccc;
      border-radius: 8px;
      background-color: #f0f0f0;
      cursor: pointer;
      transition: all 0.3s;
      /* font-size: 1rem; */
      /* display: flex; */
      flex-direction: column; /* Permet d'empiler les éléments verticalement */
      align-items: center;
      justify-content: center;
      text-align: center; /* Centre le texte */
      min-width: 110px; /*Ajuste la largeur minimale */

    
    }

    .payment-button .title {
      font-weight: bold;
      
      font-size: 0.75rem;
      margin-bottom: 5px; /* Ajoute un petit espacement entre title et price */
    }

    .payment-button .price {
      font-size: 0.75rem;
      /* color: #555; Donne une couleur plus douce au prix */
    }


    .payment-button i {
      margin-right: 8px;
      font-size: 0.75rem;
    }

    .payment-button.active {
      background-color: #016a40;
      color: white;
      border-color: #016a40;
    }

    .auto-debit {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .auto-debit label {
      cursor: pointer;
      margin-left: 8px;
      font-size: 0.8rem;
    }

</style>

<style scoped>
  /* DOSSIER MEDICAL */
  .medical-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 400px;
    margin: 5% 0%;   
    padding: 12px;
    border: 2px solid #ccc;
    border-radius: 8px;
    transition: background 0.3s, border-color 0.3s;
  }

  .medical-form .title {
    font-size: 1.1rem;
    text-align: left;
    margin-bottom: 5%;
  }

  .medical-form .question {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-items: center;
  }

  .medical-form .question label {
    font-weight: bold;
    margin-bottom: 8px;
  }

  .medical-form .icon-bg {
    background-color: #016A40;
    color: white;
    padding: 5px; /* Taille des icônes réduite */
    padding-top: 8px;
    border-radius: 50%; /* Coins arrondis */
    margin-right: 5px;
    font-size: 0.6em; /* Réduire la taille des icônes */
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 0.9em;
  }

  .medical-form select, .medical-form textarea {
    padding: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
  }
  .medical-form select:focus {
    outline: 0px solid #FFF;
  }

  .medical-form textarea {
    min-height: 80px;
    width: 95%;
  }

  .medical-form textarea:focus {
    outline: 2px solid #016A40;
  }

  .medical-form select {
    width: 100%;
  }

  .medical-form .question textarea {
    transition: opacity 0.3s ease;
  }

  .medical-form .checkbox-label {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
  }
  .medical-form .checkbox-label p{
    margin: 0;
    text-align: justify;
    font-weight: normal;
  }

</style>

<style scoped>
  .btn-submit {
    background-color: #016a40;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    margin-top: 15px;
  }

  .btn-submit:hover {
    background-color: #005934;
  }

  .btn-submit:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
</style>

<style scoped>
  .form-contact {
    max-width: 400px;
    margin: auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    padding: 12px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }

  .form-contact h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.1rem;
    margin-bottom: 5%;
    color: #000;
  }

  .form-contact .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
  }

  .form-contact .input-group label {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
  }

  .input-group select {
    padding: 0.5em;
    font-size: 1em;
    background-color: FFF;
    border: 2px solid #FFF;
    color: #000;
    border-radius: 4px;
    outline: none;
    margin-top: 1%;
  }

  .form-contact .input-group input {
    width: 95%;
    padding: 8px;
    font-size: 1em;
    border: none;
    border-bottom: 2px solid #000;
    background-color: transparent;
    transition: border-color 0.3s;
    color: #000;
    margin-top: 1%;
  }

  .form-contact .input-group input:focus {
    border-color: #016A40;
    outline: none;
  }

  .form-contact select:focus {
    outline: 2px solid #016A40;
  }

  .form-contact i {
    color: #000;
  }
</style>

<style scoped>
   .box-terms {
    display: flex;
    flex-direction: column;
    text-align: left;
    text-align: justify;
  }

  .box-terms .checkbox-label {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    cursor: pointer;
  }
  .box-terms .checkbox-label p{
    margin: 0;
    text-align: justify;
  }
</style>

<style scoped>
  .block-a2f{
    max-width: 400px;
    margin: auto;
    padding: 0px;
    text-align: center;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
  }
  .block-a2f p{
    font-size: 1rem;
  }
  .block-a2f p a{
      color: #03a5fc;
      text-decoration: none;
  }
  .block-a2f p a:hover{
      color: #08489c;
  }

  .mockup-image {
    max-width: 120px;
    margin: 20px auto;
    display: block;
  }
</style>