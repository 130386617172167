// src/utils/validationUtils.js

import i18n from './translator'; // Sans accolades



export function validateFullName(fullName) {
    let textError = "";
    
    if (!fullName) {
        textError = "Veuillez entrer votre prénom et nom de famille.";
        return { isValid: false, errorMessage: textError };
    }

    const fullNameWords = fullName.split(" ");
    if (fullNameWords.length < 2) {
        textError = "Veuillez entrer au moins deux mots pour votre nom complet.";
        return { isValid: false, errorMessage: textError };
    }

    if (fullName.length < 6) {
        textError = "Votre nom complet doit comporter au moins 6 caractères.";
        return { isValid: false, errorMessage: textError };
    }

    const fullNamePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/;
    if (!fullNamePattern.test(fullName)) {
        textError = "Le nom complet ne doit contenir que des lettres, des espaces, des tirets et des apostrophes.";
        return { isValid: false, errorMessage: textError };
    }

    if (fullName.length > 50) {
        textError = "Votre nom complet ne doit pas dépasser 50 caractères.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}


export function validateEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let textError = "";

    if (!email) {
      textError = "Veuillez entrer une adresse email.";
      return { isValid: false, errorMessage: textError };
    }

    if (!emailPattern.test(email)) {
        textError = "Veuillez entrer une adresse email valide.";
        return { isValid: false, errorMessage: textError };
    }
    
    return { isValid: true, errorMessage: "" };
}

export function validatePhone(phoneNumber) {
    let phone = phoneNumber.replace(/[\s-]/g, "").trim(); // Supprime les espaces et les tirets
    let textError = "";

    const phonePatternWithoutPrefix = /^(31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;
    const phonePatternWithPrefix = /^509(31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;

    if (!phone) {
        textError = "Le champ Numéro de téléphone est requis.";
        return { isValid: false, errorMessage: textError };
    }

    if (phone.length === 8) {
        // Si le numéro contient 8 chiffres, il doit correspondre au format sans "509"
        if (!phonePatternWithoutPrefix.test(phone)) {
            textError = "Le numéro de téléphone doit contenir 8 chiffres valides.";
            return { isValid: false, errorMessage: textError };
        }
    } else if (phone.length === 11) {
        // Si le numéro contient 11 chiffres, il doit commencer par "509" et suivre le bon format
        if (!phonePatternWithPrefix.test(phone)) {
            textError = "Le numéro de téléphone doit commencer par '509' suivi de 8 chiffres valides.";
            return { isValid: false, errorMessage: textError };
        }
    } else {
        // Si le numéro ne fait ni 8 ni 11 chiffres, il est invalide
        textError = "Le numéro de téléphone doit contenir 8 chiffres ou 11 chiffres en incluant '509'.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}


export function validateSubjectContact(subject){
    let textError = "";
    
    if (!subject) {
        textError = "Veuillez entrer un sujet.";
        return { isValid: false, errorMessage: textError };
    }

    if (subject.length > 650) {
        textError = "LE sujet ne peut dépasser 60 caractères.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}

export function formatDate(isoDateString) {
    // Créer un objet Date à partir de la chaîne de caractères ISO
    const date = new Date(isoDateString);

    // Options de formatage pour obtenir le jour, le mois et l'année
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    };

    // Utiliser l'objet Intl.DateTimeFormat pour formater la date en français
    const formatter = new Intl.DateTimeFormat('fr-FR', options);

    // Formater la date et retourner le résultat
    return formatter.format(date);
}

export function formatDateTime(isoDateString) {
    // Créer un objet Date à partir de la chaîne de caractères ISO
    const date = new Date(isoDateString);

    // Options de formatage pour obtenir le jour, le mois, l'année, l'heure et les minutes
    const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', // Optionnel si vous souhaitez inclure les secondes
        hour12: false // Utiliser le format 24h
    };

    // Utiliser l'objet Intl.DateTimeFormat pour formater la date et l'heure en français
    const formatter = new Intl.DateTimeFormat('fr-FR', options);

    // Formater la date et retourner le résultat
    return formatter.format(date);
}

export function formatShortDate(date) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('fr-FR', options);

    // Ajuster la majuscule et supprimer le point après le mois sauf pour "août"
    const dateWithCapitalizedMonth = formattedDate
        .replace('.', '')
        .replace(/\b(\w)/, char => char.toUpperCase())
        .replace('août', 'Août'); // Conserver le format pour "Août"

    return dateWithCapitalizedMonth;
}

export function formatShortDateText(date, showTime = false) {
    const monthNames = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'];

    const dateObject = new Date(date);
    const day = String(dateObject.getDate()).padStart(2, '0'); // Obtenir le jour
    const month = monthNames[dateObject.getMonth()]; // Obtenir le mois abrégé
    const year = dateObject.getFullYear(); // Obtenir l'année

    if (showTime) {
        const hours = String(dateObject.getHours()).padStart(2, '0'); // Obtenir les heures
        const minutes = String(dateObject.getMinutes()).padStart(2, '0'); // Obtenir les minutes
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    }

    return `${day} ${month} ${year}`;
}

export function formatTimeText(date) {
    const dateObject = new Date(date);
    const hours = String(dateObject.getHours()).padStart(2, '0'); // Obtenir les heures
    const minutes = String(dateObject.getMinutes()).padStart(2, '0'); // Obtenir les minutes
    return `${hours}:${minutes}`;
}


export function validatePassword(password) {
    let textError = "";

    if (!password) {
        textError = "Le champ Mot de passe est requis.";
        return { isValid: false, errorMessage: textError };
    }

    // Vérification de la longueur du mot de passe
    const hasMinimumLength = password.length >= 8;
    // Vérification des critères de sécurité
    const hasDigit = /\d/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    // Regroupement des vérifications
    if (!hasMinimumLength || !hasDigit || !hasLetter || !hasSpecialChar) {
        textError = "Votre mot de passe n'est pas assez fort. Il doit contenir au moins 8 caractères, incluant une majuscule, une minuscule, un chiffre et un symbole spécial (ex: !, @, #, $). Veuillez réessayer.";
        return { isValid: false, errorMessage: textError };
    }

    return { isValid: true, errorMessage: "" };
}

export function validateConfirmPassword(password, confirmPassword) {
  let textError = "";

  if (!confirmPassword) {
    textError= "Veuillez confirmer votre mot de passe.";
    return { isValid: false, errorMessage: textError };
  }
  if (confirmPassword !== password) {
    textError = "Les mots de passe ne correspondent pas.";
    return { isValid: false, errorMessage: textError };
  }
  return { isValid: true, errorMessage: "" };
}

export function validateContact(username) {
    const contact = username.trim(); // Supprimer les espaces avant et après
    let textError = "";

    // Vérifier si le champ est vide
    if (!contact) {
        textError = i18n.global.t('generalContent.validateContactError_1'); //"Le champ Numéro de téléphone ou Email est requis.";
        return { isValid: false, errorMessage: textError };
    }

    // Vérifier le format email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(contact)) {
        return { isValid: true, errorMessage: "" }; // Validation réussie pour email
    }

    // Vérifier le format numéro de téléphone
    const phoneRegex = /^509(?:31|36|37|38|28|34|39|29|46|48|44|47|49|22|32|33|35|42|43|40|41|55)\d{6}$/;

    if (/^\d+$/.test(contact)) {
        if (contact.length === 8 && phoneRegex.test('509' + contact)) {
            return { isValid: true, errorMessage: "" }; // Validation réussie pour numéro à 8 chiffres
        } else if (contact.length === 11 && phoneRegex.test(contact)) {
            return { isValid: true, errorMessage: "" }; // Validation réussie pour numéro à 11 chiffres avec préfixe 509
        }
    }

    // Si aucun des formats n'est valide
    textError = i18n.global.t('generalContent.validateContactError_2');  //"Veuillez entrer un email valide ou un numéro de téléphone valide.";
    return { isValid: false, errorMessage: textError };
}

export function checkAuth(autoRedirect = true) {

    const token = localStorage.getItem('tokenUser');

    // Vérifier si le token existe
    if (!token) {
        if (autoRedirect) {
            window.location.href = '/'; // Rediriger vers la page de login
        }
        return 0; // Retourner 0 si aucun token n'est trouvé
    }

    try {
        // Décomposer le token pour récupérer le payload
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const decodedToken = JSON.parse(jsonPayload);

        // Vérifier si le token contient le userId et s'il est toujours valide
        if (!decodedToken.userId || (decodedToken.exp && decodedToken.exp * 1000 < Date.now())) {
            localStorage.removeItem('token');
            window.location.href = '/'; // Rediriger vers la page de login si le token est invalide ou expiré
            return;
        }

        return decodedToken.userId;
        
    } catch (error) {
        console.error('Erreur lors de la décomposition du token:', error);
        localStorage.removeItem('token');
        window.location.href = '/'; // Rediriger vers la page de login en cas d'erreur
    }
}

export function formatCurrency(amount, currency = 'USD', locale = 'en-US') {
    // Vérifier si l'amount est un nombre et le convertir si nécessaire
    if (isNaN(amount)) {
        return 'Invalid amount';
    }

    let formattedAmount = new Intl.NumberFormat(locale, {
        style: 'decimal', // Utiliser 'decimal' pour éviter d'ajouter le symbole de la devise
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);

    if (currency === 'HTG') {
        return `${formattedAmount} HTG`;
    } else if (currency === 'USD') {
        return `$${formattedAmount} USD`;
    } else {
        return `${formattedAmount} ${currency}`;
    }
}

export function validateAccountNumber(accountNumber) {
    let textError = "";

    // Vérifier si le numéro de compte est vide
    if (!accountNumber) {
        textError = i18n.global.t('pageBasicWithdraw.account_number_required');
        return { isValid: false, errorMessage: textError };
    }

    // Supprimer les séparateurs (tirets, espaces) pour vérifier la longueur
    const cleanedAccountNumber = accountNumber.replace(/[-\s]/g, "");

    // Vérifier la longueur du numéro de compte (ex: 10 chiffres)
    if (cleanedAccountNumber.length < 8) {
        textError = i18n.global.t('pageBasicWithdraw.account_number_length');
        return { isValid: false, errorMessage: textError };
    }

    // Vérifier que le numéro de compte ne contient que des chiffres et des séparateurs autorisés
    const accountNumberPattern = /^[0-9\-\s]+$/; // Chiffres, tirets et espaces autorisés
    if (!accountNumberPattern.test(accountNumber)) {
        textError = i18n.global.t('pageBasicWithdraw.account_number_invalid');
        return { isValid: false, errorMessage: textError };
    }

    // Si tout est valide
    return { isValid: true, errorMessage: "" };
}

export function validateAccountName(accountName) {
    let textError = "";

    // Vérifier si le nom de compte est vide
    if (!accountName) {
        textError = i18n.global.t('pageBasicWithdraw.account_name_required');
        return { isValid: false, errorMessage: textError };
    }

    // Vérifier la longueur minimale du nom de compte
    if (accountName.length < 3) {
        textError = i18n.global.t('pageBasicWithdraw.account_name_min_length');
        return { isValid: false, errorMessage: textError };
    }

    // Vérifier la longueur maximale du nom de compte
    if (accountName.length > 30) {
        textError = i18n.global.t('pageBasicWithdraw.account_name_max_length');
        return { isValid: false, errorMessage: textError };
    }

    // Vérifier les caractères autorisés (y compris les accents)
    const accountNamePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/; // Lettres, chiffres, accents, espaces, tirets, points et underscores
    if (!accountNamePattern.test(accountName)) {
        textError = i18n.global.t('pageBasicWithdraw.account_name_invalid');
        return { isValid: false, errorMessage: textError };
    }

    // Si tout est valide
    return { isValid: true, errorMessage: "" };
}


  
