<template>
    <Header />
    <PageHeader title="Hôpitaux et Cliniques Partenaires" :fontSize="'1.35rem'" :goBack="customGoBack" />

    <div class="partner-page mx-auto p-6">
  
      <!-- Barre de recherche -->
      <div class="search-container">
        <input 
            v-model="searchQuery"
            type="text"
            placeholder="🔍 Rechercher un établissement..."
            class="search-input"
        />
        <select 
            v-model="selectedCategory"
            class="category-select"
        >
            <option value="">Toutes les catégories</option>
            <option value="hôpital">Hôpital</option>
            <option value="clinique">Clinique</option>
        </select>
      </div>


  
        <!-- Liste des hôpitaux -->
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div 
                v-for="hospital in filteredHospitals" 
                :key="hospital.id" 
                class="hospital-box"
            >
                <div class="icon-container">
                <i :class="hospital.category === 'hôpital' ? 'fas fa-hospital' : 'fas fa-clinic-medical'" aria-hidden="true"></i>
                <span class="icon-text">{{ hospital.category }}</span>
                </div>
                <h2 class="hospital-name">{{ hospital.name }}</h2>
                <p class="hospital-address"><i class="fas fa-map-marker-alt"></i> {{ hospital.address }}</p>
                <button 
                class="view-map-button"
                @click="viewOnMap(hospital)"
                >
                <i class="fa-solid fa-location-dot"></i> Voir sur la carte
                </button>
            </div>
        </div>

    </div>

    <Footer />
</template>
  
<script>
    import Header from '../shared/HeaderPage.vue';
    import Footer from '../shared/FooterPage.vue';
    import PageHeader from '../shared/PageHeader.vue';

    export default {
        name: 'PartnerHospitals',

        components: {
            Header,
            PageHeader,
            Footer
        },

        data() {
            return {
                searchQuery: "",
                selectedCategory: "",
                selectedHospital: null,
                hospitals: [
                    { "id": 1, "name": "Hôpital de la Paix", "address": "15 Avenue de la Paix, Port-au-Prince", "category": "hôpital", "gps": "18.5565242,-72.2991457,18.25z" },
                    { "id": 2, "name": "Centre Hospitalier Universitaire de Mirebalais", "address": "Route Nationale #3, Mirebalais", "category": "hôpital", "gps": "18.8362879,-72.1194303,17z" },
                    { "id": 3, "name": "Hôpital Saint-Louis", "address": "12 Rue de la Santé, Port-au-Prince", "category": "hôpital", "gps": "18.5354209,-72.3275874,17z" },
                    { "id": 4, "name": "Hôpital Général", "address": "Rue de l'Université, Port-au-Prince", "category": "hôpital", "gps": "18.5393845,-72.342758,17z" },
                    { "id": 5, "name": "Clinique Émilie", "address": "Rue Capois, Port-au-Prince", "category": "clinique", "gps": "18.5415, -72.3441" },
                    { "id": 6, "name": "Hôpital Sacré-Cœur", "address": "Route 1, Milot", "category": "hôpital", "gps": "19.6194, -72.2285" }
                ]

            };
        },

        computed: {
            filteredHospitals() {
                return this.hospitals.filter(hospital => 
                hospital.name.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
                (this.selectedCategory === "" || hospital.category === this.selectedCategory)
                );
            }
        },
        methods: {
            viewOnMap(hospital) {
                // Vérifier si l'objet hospital et ses coordonnées GPS existent
                if (hospital && hospital.gps) {
                    // Ouvrir Google Maps avec les coordonnées GPS
                    const gpsCoordinates = hospital.gps; // Format attendu : 'latitude,longitude'
                    const googleMapsUrl = `https://www.google.com/maps/@${gpsCoordinates}`;
                    window.open(googleMapsUrl, '_blank'); // Ouvre dans un nouvel onglet
                } else {
                    console.error('Les coordonnées GPS sont manquantes pour cet hôpital.');
                }
            },
        }
  };
</script>
  
<style scoped>
  .partner-page {
      max-width: 400px;
      margin: auto;
      padding: 0px;
      text-align: center;
      color: #ffffff;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5.5%;
      font-size: 0.8rem;
      margin-bottom: 5%;
    }

    .search-container {
        display: flex; /* Utiliser flexbox pour aligner les éléments sur la même ligne */
        max-width: 400px; /* Largeur maximale de 400px */
        width: 100%; /* Permettre une largeur de 100% jusqu'à 400px */
        gap: 10px; /* Espace entre le input et le select */
        margin: 5% 0%;
    }

    .search-input {
        flex: 1; /* Prendre tout l'espace restant disponible */
        padding: 12px 16px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

    .search-input::placeholder {
        color: #a1a1a1; /* Couleur du texte placeholder */
    }

    .search-input:focus {
        outline: none;
        border-color: #016a40; /* Couleur de bordure au focus */
        box-shadow: 0 0 5px rgba(0, 86, 179, 0.5); /* Ombre au focus */
    }

    .category-select {
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #fff; /* Couleur de fond */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

    .category-select:focus {
        outline: none;
        border-color: #016a40; /* Couleur de bordure au focus */
        box-shadow: 0 0 5px rgba(0, 86, 179, 0.5); /* Ombre au focus */
    }

    /* Styles pour améliorer l'expérience utilisateur */
    .category-select option {
        padding: 10px; /* Espace entre les options */
    }



    /* Ajout d'une règle pour la grille */
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Deux colonnes */
        gap: 20px; /* Espace entre les éléments */
    }

    /* Ajustements supplémentaires si nécessaire */
    .hospital-box {
        background: linear-gradient(135deg, #003366 0%, #1a1a1a 100%);
        color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s, box-shadow 0.3s;
    }

    /* .hospital-box:hover {
        transform: scale(1.02);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    } */

    .icon-container {
        display: flex;
        align-items: center;
        background-color: #00509E;
        color: white;
        padding: 10px;
        border-radius: 5px;
        width: fit-content;
    }

    .icon-container i {
        margin-right: 8px;
    }

    .icon-text {
        font-weight: bold;
        font-size: 0.8rem;
    }

    .hospital-name {
        font-size: 1.25rem;
        margin: 10px 0;
    }

    .hospital-address {
        color: #b0b0b0; /* Gris nuancé */
        font-size: 0.8rem;
        margin-bottom: 10px;
    }

    .view-map-button {
        background-color: #28a745; /* Vert */
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 7px;
        font-size: 0.8rem;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .view-map-button:hover {
        background-color: #218838; /* Vert plus foncé */
    }

</style>
  